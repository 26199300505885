/* .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 350px;
}

.register-form-title {
    margin-bottom: 2rem;
    color: #0070ff;
}

.register-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.register-input {
    padding: 0.8rem;
    margin: 1rem 0;
    font-size: 1rem;
}

.register-submit-button {
    margin: 1rem auto;
    padding: 0.8rem;
    width: 100%;
    background-color: #0070ff;
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.register-error-message {
    color: red;
    padding: 1rem;
    font-size: 1.3rem;
} */

.register {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 3rem;
}

.register-img {
    width: 280px;
    height: auto;
}

.registerinfo {
    display: flex;
    flex-direction: column;
}

.register-info-header {
    margin-bottom: 1rem;
}

.register-info-body {
    text-align: justify;
    font-size: 1.2rem;
    font-family: sans-serif;
    margin-bottom: 1rem;
}

.register-info-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.register-contact-button {
    padding: 0.5rem;
    background-color: #0070ff;
    color: white;
    border: 1px solid transparent;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
}

.register-signup-button {
    padding: 0.5rem;
    background-color: transparent;
    color: #0070ff;
    border: 1px solid #0070ff;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
}

@media (min-width:1200px) {
    .register {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: row;
        margin-top: 0;
    }
    .register-img {
        margin-right: 3rem;
    }
    .registerinfo {
        width: 400px;
    }
}