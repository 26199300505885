.login-page {
    width: 100%;
    height: 100%;
    background-image: url("../../img/login.png");
    background-size: cover;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 350px;
}

.login-form-title {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #0070ff;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid #0070ff;
}

.login-form-label {
    font-size: 1rem;
}

.login-input {
    padding: 0.8rem;
    font-size: 1rem;
    margin: 1rem 0;
    border: none;
}

.form-login-button {
    margin: auto;
    padding: 0.8rem;
    /* width: 200px; */
    width: 100%;
    background-color: #0070ff;
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.form-addon-info {
    margin: 0.5rem auto;
    font-size: 1rem;
}

.form-register-button {
    padding: 0.8rem;
    font-size: 1.2rem;
    width: 350px;
    background-color: white;
    color: #0070ff;
    border: 1px solid black;
    border-radius: 1rem;
    margin: auto;
    cursor: pointer;
}

.login-form-error {
    padding: 0rem 0rem 0.5rem 0;
    color: red;
}

.message-area {
    color: red;
    padding: 1rem;
    font-size: 1.3rem;
}