.note-list {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 8px rgba(18, 18, 20, 0.81);
}

.note-list-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.note-list-title {
    font-weight: bold;
    color: black;
}

.note-list-tag {
    padding: 0.25rem;
    color: white;
    border-radius: 0.5rem;
    font-weight: bold;
}

.note-list-Easy {
    border: 1px solid rgb(97, 201, 97);
    background-color: white;
    color: rgb(97, 201, 97);
}

.note-list-Easy\+ {
    border: 1px solid green;
    background-color: white;
    color: green;
}

.note-list-Medium {
    background-color: blue;
    color: white;
}

.note-list-Medium\+ {
    background-color: blueviolet;
    color: white;
}

.note-list-Hard {
    background-color: red;
    color: white
}

.note-list-Hard\+ {
    background-color: darkred;
    color: white;
}

.note-list-link {
    color: black;
    margin-left: 1rem;
}

@media (min-width: 800px) {
    .note-list {
        width: 100%;
    }
}