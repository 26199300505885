/* #menu-toggle {
    opacity: 0;
}

#menu-toggle:checked+.menu-btn>span {
    transform: rotate(45deg);
}

#menu-toggle:checked+.menu-btn>span::before {
    top: 0;
    transform: rotate(0deg);
}

#menu-toggle:checked+.menu-btn>span::after {
    top: 0;
    transform: rotate(90deg);
}

#menu-toggle:checked~.nav-content {
    right: 0 !important;
} */

.menu-btn.rotate>span {
    /* transform: s(90deg); */
    /* display: none; */
    background-color: transparent;
}

.menu-btn.rotate>span::before {
    top: 0;
    transform: rotate(45deg);
}

.menu-btn.rotate>span::after {
    top: 0;
    transform: rotate(-45deg);
}

.menu-btn {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
    border: none;
    background-color: transparent;
}

.menu-btn>span,
.menu-btn>span::before,
.menu-btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #0070ff;
    transition-duration: .25s;
}

.menu-btn>span::before {
    content: '';
    top: -8px;
}

.menu-btn>span::after {
    content: '';
    top: 8px;
}

.nav-content {
    /* display: flex;
    flex-direction: column;
    width: 300px;
    /* top: 0;
    right: -100%; */
    /* position: fixed;
    margin: 0;
    list-style: none; */
    /* display: block; */
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    /* left: -100%; */
    width: 300px;
    height: 320px;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #ECEFF1;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
}

.menu-li {
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    text-decoration: none;
    transition-duration: .25s;
    text-align: center;
}

.mobile-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.mobile-login-button {
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0.5rem;
    text-align: center;
    width: 60px;
    cursor: pointer;
}

.mobile-register-button {
    padding: 0.5rem;
    background-color: #0070ff;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.5rem;
}

.mobile-logout-button {
    padding: 0.5rem;
    background-color: #0070ff;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.inactive {
    display: none;
}