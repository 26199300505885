.singlenote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin: 2rem auto 2rem auto;
}

.singlenote-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.update-noteimg-button {
    margin: 1rem auto
}

.editimg-button {
    border: none;
    background-color: transparent;
}

.cat {
    display: flex;
    flex-direction: row;
}

.single-cate {
    padding: 0.5rem;
}

.singlenote-img {
    padding: 0.5rem;
    width: 100%;
    height: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* border: 1px solid black;
    border-radius: 2rem; */
    margin-bottom: 1rem;
}

.singlenote-img:hover,
.singlenote-img:focus-within {
    transform: scale(1.05);
}

.singlenote-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
}

.singlenote-title {
    padding: 0;
    font-size: 1.5rem;
    color: #0070ff;
}

.wrap-button {
    border: none;
    background-color: transparent;
}

.edit-button {
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    /* color: green; */
}

.delete-button {
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    color: red;
}

.singlenote-body {
    display: flex;
    flex-direction: column;
}

.button-div {
    display: flex;
    flex-direction: row;
}

.singlenote-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.singlenote-link {
    color: black;
    text-decoration: underline;
}

.singlenote-tag {
    padding: 0.1rem 0.25rem;
    border: none;
    margin-right: auto;
    margin-bottom: 0.5rem;
    font-weight: bold;
    border-radius: 0.25rem;
}

.singlenote-tag-Easy {
    color: rgb(97, 201, 97);
    border: 1px solid rgb(97, 201, 97);
}

.singlenote-tag-Easy\+ {
    color: green;
    border: 1px solid green;
}

.singlenote-tag-Medium {
    color: blue;
    border: 1px solid blue;
}

.singlenote-tag-Medium\+ {
    color: blueviolet;
    border: 1px solid blueviolet;
}

.singlenote-tag-Hard {
    color: red;
    border: 1px solid red;
}

.singlenote-tag-Hard\+ {
    color: darkred;
    border: 1px solid darkred;
}

.singlenote-cat {
    margin-bottom: 0.5rem;
}

.cat {
    list-style: none;
    display: flex;
    flex-direction: row;
}

.single-cate {
    display: flex;
    padding: 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #0070ff;
    color: white;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.singlenote-content {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 5px rgb(138, 137, 137);
    font-size: 1.1rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
}

.note-content-p {
    font-size: 1.2rem;
    width: 100%;
    overflow-wrap: break-word;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    text-align: justify;
}


/* || update-note-body */

.update-note-body {
    display: flex;
    flex-direction: column;
}

.update-single-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.update-content-div {
    display: flex;
    flex-direction: column;
}

.update-note-label {
    font-weight: bold;
    margin-right: 0.5rem;
}

.update-row-input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.update-textarea {
    border-radius: 0.5rem;
    padding: 0.5rem;
    resize: none;
    margin-top: 0.5rem;
    box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.update-button-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
}

.update-confirm-button {
    padding: 0.5rem;
    background-color: #0070ff;
    border: none;
    border-radius: 0.5rem;
    color: white;
}

.update-cancel-button {
    padding: 0.5rem;
    background-color: white;
    border: 1px solid #0070ff;
    border-radius: 0.5rem;
    color: red;
}

.goback-to-notes {
    margin: 1rem auto;
    background-color: #0070ff;
    color: white;
    font-size: 1.1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

@media (min-width: 1000px) {
    .singlenote {
        width: 95%;
        flex-direction: row;
        align-items: center;
    }
    .singlenote-wrapper {
        width: 60%;
        margin-right: 3rem;
        margin-bottom: 0;
    }
    .singlenote-body {
        width: 35%;
    }
    .update-note-body {
        width: 40%;
    }
}

@media (min-width: 1500px) {
    .singlenote {
        width: 80%;
    }
}