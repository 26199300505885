.home-page {
    display: flex;
    flex-direction: column;
    /* margin-left: 0.5rem;
    margin-right: 0.5rem; */
}

.hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1800px;
}

.hero-text {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

.hero-first-title {
    margin-bottom: 1rem;
}

.hero-title {
    margin-bottom: 1rem;
}

.hero-image {
    width: 100%;
}

.hero-button {
    border: none;
    background-color: #0070ff;
    padding: 0.5rem;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
}

.cat-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin: 2rem auto;
    color: #0070ff;
}

.ds-content {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding-top: 2rem;
}

.ds-text {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

.ds-img {
    width: 100%;
    height: auto;
}


/* .cat-ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    padding-bottom: 2rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.single-cat {
    grid-column: span 3;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
    width: 100%;
    border: 1px solid black;
    border-radius: 1rem;
} */


/* 
.data-structure-types {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, 320px);
    justify-content: center;
}

.single-cat:hover {
    border: none;
    background-color: #0070ff;
    color: white;
    font-weight: bold;
} */

.hero-wrapper {
    padding: 0.5rem;
}

.algo-wrapper {
    background-color: #f5f5f5;
    padding: 0.5rem;
}

.algorithms {
    width: 100%;
}

.cat-ul-algo {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    padding-bottom: 2rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.single-cat-algo {
    display: flex;
    grid-column: span 3;
    padding: 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 100%;
    border: 1px solid black;
    border-radius: 1rem;
}

.single-cat-algo:hover {
    border: none;
    background-color: #0070ff;
    color: white;
    font-weight: bold;
}


/*  */


/* ||contact-section */

.contact-wrapper {
    background-color: #6699cc;
    padding: 0.5rem;
}

.contact-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-contact-header {
    margin: 2rem auto 1rem auto;
    color: white;
}

.about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-img {
    border-radius: 50%;
    width: 100px;
    margin-bottom: 1rem;
}

.icon-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.icon-button {
    font-size: 1.1rem;
    padding: 0.5rem;
    margin: 0;
}

.icon-button:hover,
.icon-button:focus-within {
    color: #0070ff;
    font-size: 1.5rem;
}

.about-intro {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 0.5rem;
    text-align: center;
}

.contact-bottom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.contactsection-message {
    text-align: center;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1rem;
    font-weight: bold;
}

.contact-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-contact-button {
    padding: 0.25rem;
    color: white;
    background-color: #0070ff;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    margin-right: 2rem;
}

.footer-signup-button {
    padding: 0.25rem;
    color: #0070ff;
    background-color: white;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

@media (min-width: 800px) {
    /* .cat-ul {
        grid-template-columns: repeat(12, 1fr);
    }
    .single-cat {
        grid-column: span 3;
    } */
    .cat-ul-algo {
        grid-template-columns: repeat(12, 1fr);
    }
    .single-cat-algo {
        grid-column: span 4;
    }
}

@media (min-width: 1024px) {
    .home-page {
        margin: 0;
    }
    .hero {
        /* flex-direction: row; */
        position: relative;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .algorithms {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .contact {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .hero-first-title {
        color: black;
        background-color: white;
        opacity: 0.8;
        padding: 0.5rem;
    }
    .hero-text {
        /* flex: 4; */
        position: absolute;
        top: 50%;
        left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .text-body {
        margin: auto;
    }
    .hero-img {
        width: 100%;
    }
    .ds-img {
        flex: 8;
        width: 100%;
        height: auto;
    }
    .ds-content {
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 0;
    }
    .ds-text {
        margin-left: 2rem;
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cat-ul-algo {
        grid-template-columns: repeat(12, 1fr);
    }
    .single-cat-algo {
        grid-column: span 3;
    }
    .contact-bottom-section {
        flex-direction: row;
        align-items: center;
    }
    .contactsection-message {
        margin-bottom: 0;
    }
}