.signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 350px;
}

.signup-page-header {
    color: #0070ff;
    margin-bottom: 2rem;
}

.signup-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signup-form-error {
    padding: 0.5rem 0;
    color: red;
}

.signup-input-label {
    margin-bottom: 0.6rem;
    font-family: sans-serif;
}

.signup-input {
    padding: 0.5rem;
    font-size: 1.1rem;
    margin-bottom: 0.6rem;
}

.signup-page-button {
    margin: 1rem auto;
    padding: 0.8rem;
    width: 150px;
    background-color: #0070ff;
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.signup-page-button:hover {
    background-color: #0fc0fc;
}

.signup-error-message {
    color: red;
    padding: 1rem;
    font-size: 1.3rem;
}