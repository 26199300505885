* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: sans-serif; */
}

ul {
    list-style-type: none;
}

.app-page {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
}

.react-link {
    color: black;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}


/* .app-body {
    margin-bottom: 3rem;
} */

@media (min-width: 1024px) {
    /* .app-body {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    } */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}