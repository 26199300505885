.addnote-page {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    /* width: 500px; */
}

.add-form-title {
    margin-bottom: 2rem;
    color: #0070ff;
}

.add-form {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 0.6rem;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.note-img {
    width: 100%;
    height: auto;
    margin: 1rem auto;
}

.form-content {
    display: flex;
    flex-direction: column;
}


/* .row-input {
    display: flex;
    flex-direction: column;
} */


/* 
.input-title {
    margin-bottom: 0.5rem;
} */

.normal-input {
    padding: 0.5rem;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-family: sans-serif;
    resize: none;
}

#fileinput {
    display: none;
}

.form-content-radio {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.25rem;
    margin: 1rem 0;
    align-items: center;
    justify-content: center;
}

.checkbox {
    grid-column: span 2;
    background-color: transparent;
    color: #0070ff;
    border: 1px solid #0070ff;
    border-radius: 1rem;
    padding: 0.5rem 0;
    font-weight: bold;
    margin: 0 1.5rem;
    cursor: pointer;
}

.active-box {
    background-color: #0070ff;
    color: white;
}


/* .radio_check {
    display: none;
} */


/* .radio-check:focus-within {
    display: inline-block;
} */


/* .checkbox:focus-within .radio_check {
    
} */

.radio-label {
    /* padding: 0.25rem; */
    background-color: #fff;
    border-radius: 1rem;
    padding: 0 0.75rem;
    cursor: pointer;
    font-size: 1.2rem;
}


/* .radio-label:hover {
    background-color: #0070ff;
    color: white;
} */

.radio-label-checked {
    background-color: #0070ff;
    color: white;
}

.add-img-button {
    border: none;
    background-color: transparent;
}

.form-content-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addimg-button {
    border: none;
    background-color: transparent;
}

.note-submit-button {
    border: none;
    background-color: transparent;
}

.icon-addpage {
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
}

.fa-rectangle-xmark:hover {
    color: red;
}

.fa-floppy-disk:hover {
    color: green;
}

.fa-image:hover {
    color: cornflowerblue;
}

.addpage-error {
    color: red;
    margin: 1rem;
}

@media (min-width: 800px) {
    .add-form {
        width: 600px;
    }
    .form-content-radio {
        grid-template-columns: repeat(12, 1fr);
    }
    .checkbox {
        grid-column: span 2;
        margin: 0 0.5rem;
    }
}