.topbar {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    padding: 0.5rem;
    background-color: white;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 60px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.topbar.active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0.1, 0.5);
    opacity: 0.9;
}

.skip-button {
    background-color: #0070ff;
    padding: 0.5rem;
    position: absolute;
    top: -50px;
    border: none;
    color: white;
    width: 150px;
    margin: auto;
}

.skip-button:focus-within {
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.topbar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    background-color: white;
}

.topbar-logo {
    flex: 2;
}

.website-title {
    font-size: 1.8rem;
    color: #0070ff;
}

.topbar-link {
    display: none;
}

.topbar-button {
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;
    align-items: center;
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menu-button {
    text-decoration: none;
    color: #0070ff;
    font-size: 1.1rem;
    /* font-weight: bold; */
}

.menu-button:hover {
    text-decoration: underline;
}

.login-button {
    border: none;
    padding: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

.register-button {
    color: white;
    background-color: #0070ff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
}

.user-name {
    font-size: 1rem;
}

.logout-button {
    color: white;
    background-color: #0070ff;
    border: none;
    margin-left: 1rem;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 1rem;
}

.logout-button:hover {
    cursor: pointer;
}

#main-content {
    display: none;
}

@media (min-width: 1000px) {
    .topbar-link {
        flex: 9;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .navbar {
        flex: 9;
    }
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .topbar-button {
        flex: 3;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
    .topbar-hamburger {
        display: none;
    }
}

@media (min-width: 1300px) {
    .topbar-wrapper {
        width: 80%;
    }
}


/* #f5f5f5 */


/* section: nth-child(odd){
    backgroud-color: #f5f5f5;
 } */