footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0070ff;
    padding: 0;
    margin: 0;
}

.footer-section {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}

.fa-linkedin-in {
    color: white;
    padding: 0 0.5rem;
    cursor: pointer;
}

.fa-github {
    padding: 0 0.5rem;
    color: white;
    cursor: pointer;
}

.fa-user {
    padding: 0 0.5rem;
    color: white;
    cursor: pointer;
}

.footer-p {
    font-size: 1rem;
    color: white;
    font-weight: bold;
}

@media (min-width: 800px) {
    .footer-section {
        width: 80%;
    }
}