.notes-page {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.notes-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.searchbar {
    display: flex;
    margin: 1rem auto 1rem auto;
}

.search-button {
    margin-right: 0.5rem;
    background-color: #0070ff;
    color: white;
    width: 60px;
    border-radius: 0.5rem;
    font-weight: bold;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.reset-button {
    background-color: transparent;
    color: #0070ff;
    width: 50px;
    border-radius: 0.5rem;
    font-weight: bold;
    border: 1px solid #0070ff;
    font-size: 1rem;
    cursor: pointer;
}

.search-input {
    padding: 0.5rem;
    font-size: 1.2rem;
    width: 250px;
    margin-right: 1rem;
    border: 1px solid #3498db;
}

.fetching-div {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    align-items: center;
}

.error-message-div {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 1.5rem;
    color: red;
    transform: translate(-50%, -50%);
}

.spinner-title {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

.notes-title-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.notes-title-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fa-list {
    font-size: 1.2rem;
    color: black;
    cursor: pointer;
}

.fa-grip {
    font-size: 1.2rem;
    margin-left: 1rem;
    cursor: pointer;
    margin-right: 1rem;
}

.chosen {
    color: #0070ff;
    font-size: 1.5rem;
}

.notes-cat-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0070ff;
    margin-left: 1rem;
}

.notes {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-section {
    width: 95%;
    margin: auto;
    margin-bottom: 2rem;
}

.notes-list {
    display: flex;
    flex-direction: column;
}

.notes-number {
    margin-left: .5rem;
}

@media (min-width:800px) {
    .notes-page {
        width: 100%;
        flex-direction: row;
        margin: auto auto 2rem auto;
    }
    .sidebar-section {
        width: 25%;
        margin: 0.5rem 1rem auto 1rem;
    }
    .notes-wrapper {
        width: 65%;
        margin: auto;
    }
    .notes {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }
    .notes-cat-title {
        margin-left: 0;
    }
}

@media (min-width:1324px) {
    .notes-page {
        width: 80%;
    }
    .sidebar-section {
        width: 20%;
    }
    .notes-wrapper {
        width: 70%;
    }
    .notes {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
}