.sidebar {
    display: flex;
    flex-direction: column;
    margin: auto;
    /* align-items: center;
    justify-content: center; */
}

.sidebar-difficulty {
    display: flex;
    flex-direction: column;
}

.sidebar-category {
    display: grid;
    grid-template-columns: repeat(6 1fr);
    align-items: center;
}

.sidebar-title {
    width: 100%;
    font-weight: bold;
    font-size: 1.2rem;
    font-family: sans-serif;
    color: #0fc0fc;
    padding: 0.5rem 0;
    border-top: 1px solid #0fc0fc;
    border-bottom: 1px solid #0fc0fc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.category-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    margin: 0.25rem 0;
}

.all-notes-link {
    background-color: #0070ff;
    color: white;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
}

.li-item {
    padding: 0.25rem;
    margin: 0 0.5rem;
    font-size: 1.1rem;
    text-align: center;
    align-items: center;
}

.li-item:hover {
    background-color: #0fc0fc;
    color: white;
    border-radius: 0.5rem;
    /* font-weight: bold; */
}

.li-item-link:focus-within {
    background-color: #0fc0fc;
    color: white;
    border-radius: 0.5rem;
}


/* @media (min-width: 1024px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
} */