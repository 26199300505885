.contact-page {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 0.6rem;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.contact-page-header {
    margin-bottom: 2rem;
    text-align: center;
    color: #0070ff;
    font-size: 2rem;
    font-weight: bold;
}

.contact-row-input {
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    resize: none;
}

.contactform-submit-button {
    width: 80px;
    margin: 1rem auto;
    padding: 0.6rem;
    background-color: #0070ff;
    border: none;
    border-radius: 0.5rem;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.contactform-submit-button:hover,
.contactform-submit-button:focus-within {
    color: #0070ff;
    background-color: white;
    border: 1px solid #0070ff;
}

.submit-form-error {
    margin-top: 1rem;
    font-size: 1.3rem;
}

.contact-form-error {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: red;
}

@media (min-width: 800px) {
    .contact-form {
        width: 600px;
    }
}