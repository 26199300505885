.note {
    padding: 0.5rem;
    width: 90%;
    height: 200px;
    margin: 0px 0 1.5rem 0;
    border-radius: 1rem;
    background-color: #9cc3d5ff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px rgba(65, 118, 126, 0.81);
}

.note-img {
    height: 200px;
}

.note-body {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 192px;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.note-title-p {
    cursor: pointer;
    font-weight: bold;
    padding: 0;
    margin: 0;
    /* text-overflow: ellipsis;
    text-align: justify; */
}

.note-title {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.note-tag {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    width: auto;
    text-align: center;
    background-color: white;
    font-weight: bold;
}

.note-tag-Easy {
    color: rgb(97, 201, 97);
}

.note-tag-Easy\+ {
    color: green;
}

.note-tag-Medium {
    color: blue;
}

.note-tag-Medium\+ {
    color: blueviolet;
}

.note-tag-Hard {
    color: red;
}

.note-tag-Hard\+ {
    color: darkred;
}

.note-link {
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.problem-url {
    color: black;
}

.problem-url:hover,
.problem-url:focus-within {
    color: white;
    font-weight: bolf;
    text-decoration: underline;
}

.note-catg {
    margin-bottom: 0.5rem;
}

a {
    text-decoration: none;
}

.note-catg-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0.5rem;
}

.catg-list-item {
    margin-right: 0.5rem;
    text-decoration: underline;
}

.note-catg-link:hover {
    cursor: pointer;
}

.note-desc {
    margin-bottom: 0.5rem;
    white-space: wrap;
    font-family: "Varela Round", sans-serif;
    width: 100%;
    font-size: 0.8rem;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: auto; */
}

.note-view-link {
    align-self: center;
    margin-top: auto;
}

.note-view-button:hover {
    cursor: pointer;
    color: #0070ff;
}

.note-view-button {
    width: 60px;
    border: none;
    background-color: transparent;
    color: white;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

@media (min-width: 800px) {
    .note {
        width: 200px;
        grid-column: span 3;
    }
}