.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.leftpart {
    margin-bottom: 1rem;
}

b {
    padding: 0 0.3rem 0 0.25rem;
}

.page-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.function-button {
    background-color: #0070ff;
    border: none;
    color: white;
    border-radius: 0.8rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.function-button-disabled {
    background-color: lightgray;
    color: black;
}

.page-button {
    background-color: transparent;
    border: none;
    color: black;
    padding: 0 0.5rem;
    cursor: pointer;
}

.page-button:hover {
    text-decoration: underline;
}

.page-button-active {
    text-decoration: underline;
    background-color: lightblue;
    padding: 0.5rem;
}

@media (min-width: 600px) {
    .pagination {
        flex-direction: row;
        justify-content: space-between;
    }
    .leftpart {
        margin-bottom: 0;
    }
}

@media (min-width: 900px) {
    .pagination {
        flex-direction: row;
        justify-content: space-around;
    }
    .leftpart {
        margin-bottom: 0;
    }
}